<template>
    <div>

        <a-table
            :columns="columnas"
            :data-source="usuarioPaginado ? usuarioPaginado.result : []"
            :pagination="usuarioPaginado ? usuarioPaginado.pagination : {}"
            :row-key="record => record.id"
            :loading="isLoading"
            @change="handleTableChange"
        >
            <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'acciones'">
                    <a>Delete</a>
                </template>
            </template>

        </a-table>

    </div>
</template>

<script>
    import { defineComponent, computed, onMounted } from 'vue';
    import { useStore } from "vuex";

    const columnas = [
        { title: 'Usuario', dataIndex: 'usuario', sorter: true, },
        { title: 'Rol', dataIndex: 'rol_id', sorter: true, },
        { title: 'Estado', dataIndex: 'activo', sorter: true, },
        { title: 'Acciones', dataIndex: 'acciones', },
    ];

    export default defineComponent({

        setup() {
            const store = useStore();

            const consulta = {
                pagina: 1,
                buscar: "",
            }
            
            // getters
            const isLoading = computed(() => store.getters["usuario/isLoading"]);
            const usuarioPaginado = computed(() => store.getters["usuario/usuarioPaginado"]);

            const ObtenerTodosUsuarios = data => { 
                store.dispatch('usuario/ObtenerTodosUsuarios', data);
            }

            ObtenerTodosUsuarios(consulta);

            const handleTableChange = (pag, filters, sorter) => {
                ObtenerTodosUsuarios({buscar: '', pagina: pag.current});
            };

            return { isLoading, usuarioPaginado, columnas, handleTableChange}
        }
    });
</script>