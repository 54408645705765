<template>
    <div>
		<a-row :gutter="24" type="flex">
			<a-col :span="24" class="mb-24">
            	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">

                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">Lista de Usuarios</h5>
                            </a-col>
                            <a-col :span="24" :md="12" class="d-flex align-items-center justify-content-end">
                                <a-button type="primary" @click="showModal">Nuevo Usuario</a-button>
                            </a-col>
                        </a-row>
                    </template>

                    <ListadoUsuario />

                </a-card>
                <a-modal v-model:visible="visible" width="1000px" title="Basic Modal" @ok="handleOk">
                    <FormularioUsuario />
                </a-modal>
			</a-col>
		</a-row>
    </div>
</template>

<script>
    import { defineComponent, ref } from 'vue';

    import ListadoUsuario from '@/components/tenant/usuario/ListadoUsuario';
    import FormularioUsuario from '@/components/tenant/usuario/FormularioUsuario';
 
    export default defineComponent({
        layout: {
            name: 'TenantLayout',
            props: {}
        },  

        components: { ListadoUsuario, FormularioUsuario },

        setup() {
            const visible = ref(false);
            const authorsHeaderBtns = 'all';

            const showModal = () => {
                visible.value = true;
            };

            const handleOk = e => {
                console.log(e);
                visible.value = false;
            };

            return{authorsHeaderBtns, visible, showModal, handleOk,}
        }
    });
</script>